<template>
  <div class="mb-3">
    <label for="inputPassword" class="form-label"><b>{{ $translator.t('NEW_PASSWORD') }}</b></label>
    <div class="input-group">
      <input
          :type="showPassword ? 'text' : 'password'"
          id="inputPassword"
          class="form-control"
          aria-describedby="passwordHelpBlock"
          v-model="newPassword"
      />
      <button
          class="btn btn-outline-secondary"
          type="button"
          :disabled="!newPassword"
          @click="togglePasswordVisibility"
      >
        {{ showPassword ? $translator.t('HIDE_PASSWORD') : $translator.t('SHOW_PASSWORD') }}
      </button>
    </div>
  </div>
  <div class="mb-3">
    <label for="inputConfirmPassword" class="form-label"><b>{{ $translator.t('CONFIRM_PASSWORD') }}</b></label>
    <div class="input-group">
      <input
          :type="showConfirmPassword ? 'text' : 'password'"
          id="inputConfirmPassword"
          class="form-control"
          aria-describedby="confirmPasswordHelpBlock"
          v-model="confirmationPassword"
          :disabled="!isPasswordValid"
      />
      <button
          class="btn btn-outline-secondary"
          type="button"
          :disabled="!isPasswordValid"
          @click="toggleConfirmPasswordVisibility"
      >
        {{ showConfirmPassword ? $translator.t('HIDE_PASSWORD') : $translator.t('SHOW_PASSWORD') }}
      </button>
    </div>
  </div>

  <ul class="password-requirements mt-2" v-if="newPassword !== null">
    <li :class="{'text-success': hasLowercase, 'text-danger': !hasLowercase}">
      Lower-case letter{{ $translator.t('LOWER_CASE_PASSWORD') }}
    </li>
    <li :class="{'text-success': hasUppercase, 'text-danger': !hasUppercase}">
      Upper-case letter{{ $translator.t('UPPER_CASE_PASSWORD') }}
    </li>
    <li :class="{'text-success': hasNumber, 'text-danger': !hasNumber}">
      Number{{ $translator.t('NUMBER_PASSWORD') }}
    </li>
    <li :class="{'text-success': hasSpecialChar, 'text-danger': !hasSpecialChar}">
      Special character [@.$#!%*?-&^]{{ $translator.t('SPECIAL_CHARACTER_PASSWORD') }}
    </li>
    <li :class="{'text-success': hasValidLength, 'text-danger': !hasValidLength}">
      At least 12 characters{{ $translator.t('LEAST_12_PASSWORD') }}
    </li>
    <li v-if="!['', null].includes(confirmationPassword)"
        :class="{'text-success': hasValidConfirm, 'text-danger': !hasValidConfirm}">
      The passwords match{{ $translator.t('MATCH_CONFIRM_PASSWORD') }}
    </li>
  </ul>

  <div v-if="errors.password" id="passwordHelpBlock" class="form-text help-block">
    {{ errors.password }}
  </div>
</template>

<script>
export default {
  props: {
    errors: Object,
  },
  data() {
    return {
      newPassword: null,
      confirmationPassword: null,
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    emitPasswordUpdate() {
      this.$emit('new-password', {
        newPassword: this.newPassword,
        confirmationPassword: this.confirmationPassword,
        isPasswordValid: this.isPasswordValid,
        hasValidConfirm: this.hasValidConfirm
      });
    }
  },
  watch: {
    newPassword() {
      this.emitPasswordUpdate();
    },
    confirmationPassword() {
      this.emitPasswordUpdate();
    }
  },
  computed: {
    hasLowercase() {
      return this.newPassword !== null && /[a-z]/.test(this.newPassword);
    },
    hasUppercase() {
      return this.newPassword !== null && /[A-Z]/.test(this.newPassword);
    },
    hasNumber() {
      return this.newPassword !== null && /\d/.test(this.newPassword);
    },
    hasSpecialChar() {
      return this.newPassword !== null && /[@.#$!%*?\-&^]/.test(this.newPassword);
    },
    hasValidLength() {
      return this.newPassword.length >= 12
    },
    hasValidConfirm() {
      return this.newPassword !== null &&
          this.confirmationPassword !== null &&
          this.newPassword === this.confirmationPassword
    },
    isPasswordValid() {
      return (
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasNumber &&
          this.hasSpecialChar &&
          this.hasValidLength
      );
    }
  },
};
</script>

<style scoped>
.help-block {
  color: red;
}
</style>
