<template>
  <div class="modal fade"
       :id="'deleteInjectionModal_' + injection.id"
       tabindex="-1"
       aria-labelledby="deleteInjectionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="deleteInjectionModalLabel">
            {{ $translator.t('DELETE_INJECTION_HEADER') }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="modal-body-title"><b>{{ $translator.t('DELETE_INJECTION_CONFIRMATION_MESSAGE') }}:</b></p>
          <p>{{ $translator.t('UNITS') }}: <b>{{ injection.numberOfUnits }}</b></p>
          <p>{{ $translator.t('DATE') }}: <b>{{ injection.created }}</b></p>
          <p>{{ $translator.t('FASTER') }}: <b>{{ injection.fasterInjection }}</b></p>
          <p>{{ $translator.t('REASON') }}: <b>{{ injection.reason }}</b></p>
          <p>{{ $translator.t('STOCK') }}: <b>{{ injection.stock.used }}/{{ injection.stock.total }}
            - {{ injection.stock.company }}</b></p>
        </div>
        <div class="modal-footer">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">
            {{ $translator.t('CANCEL') }}
          </button>
          <button type="button"
                  class="btn btn-danger"
                  @click="deleteInjections(injection.id)"
                  data-bs-dismiss="modal">
            {{ $translator.t('YES') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deleteInjections from "@/composables/injection/deleteInjections";
import getInjections from "@/composables/injection/getInjections";

export default {
  props: {
    injection: Object
  },
  methods: {
    deleteInjections(injectionId) {
      deleteInjections(injectionId)
          .then(() => {
            getInjections()
                .then(result => this.$emit('update-injection', result))
                .catch(err => console.log(err.message))
          })
          .catch(err => console.log(err.message))
    },
  }
}
</script>

<style scoped>
.modal-body-title {
  font-size: large;
}
</style>
