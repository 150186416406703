<template>
  <div>
    <h1>
      {{ $translator.t('STOCK') }}
      <button type="button"
              class="btn btn-success btn"
              data-bs-toggle="modal"
              data-bs-target="#addStockModal">
        +
      </button>
    </h1>

    <StockForm
        :stock="edit_stock"
        :errors="errors"
        :valid_success="valid_success"
        @update-stocks="updateStocks"
        @update-errors="updateErrors"
        @update-valid-success="updateValidSuccess"
    />

    <StockList
        :stocks="stocks['stocks']"
        :errors="errors"
        :valid_success="valid_success"
        @update-stocks="updateStocks"
        @update-errors="updateErrors"
        @update-valid-success="updateValidSuccess"
    />

    <div v-if="this.errors.delete_stock" class="alert alert-danger mt-3">{{ this.errors.delete_stock }}</div>
    <div v-if="this.valid_success.update" class="alert alert-success mt-3">{{ this.valid_success.update }}</div>

  </div>
</template>

<script>
import getStocks from "@/composables/stock/getStocks";
import StockList from "@/components/Stock/StockList.vue";
import StockForm from "@/components/Stock/StockForm.vue";

export default {
  components: {StockList, StockForm},
  data() {
    return {
      stocks: [],

      edit_stock: {
        stock_id: null,
        stock_company: null,
        stock_date: null,
        stock_terminated_date: null,
        stock_units: null,
      },

      stock_company: null,
      stock_date: null,
      stock_terminated_date: null,
      stock_units: null,

      errors: {},
      valid_success: {},
    }
  },
  methods: {
    updateStocks(updatedStocks) {
      this.stocks = updatedStocks;
    },
    updateErrors(errors) {
      this.errors = errors;
    },
    updateValidSuccess(success) {
      this.valid_success = success;
    }
  },
  created() {
    getStocks()
        .then(result => this.stocks = result)
        .catch(err => console.log(err.message))
  }
};
</script>
