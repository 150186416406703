<template>
  <div class="modal fade" id="addStockModal" tabindex="-1" aria-labelledby="addStockModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addStockModalLabel">{{ $translator.t('NEW_STOCK') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-if="valid_success.message" class="valid-success">{{ valid_success.message }}</p>
          <div class="mb-3">
            <label for="companyInput" class="form-label">{{ $translator.t('COMPANY') }}</label>
            <input type="text" class="form-control" id="companyInput" v-model="stock_company" required>
            <p v-if="errors.stock_company" class="valid-error">{{ errors.stock_company }}</p>
          </div>
          <div class="mb-3">
            <label for="dateInput" class="form-label">{{ $translator.t('DATE') }}</label>
            <input type="date" class="form-control" id="dateInput" v-model="stock_date" required>
            <p v-if="errors.stock_date" class="valid-error">{{ errors.stock_date }}</p>
          </div>
          <div class="mb-3">
            <label for="terminatedInput" class="form-label">{{ $translator.t('TERMINATED') }}</label>
            <input type="date" class="form-control" id="terminatedInput" v-model="stock_terminated_date" required>
            <p v-if="errors.stock_terminated_date" class="valid-error">
              {{ errors.stock_terminated_date }}
            </p>
          </div>
          <div class="mb-3">
            <label for="unitsInput" class="form-label">{{ $translator.t('UNITS') }}</label>
            <input type="number"
                   class="form-control"
                   id="unitsInput"
                   v-model="stock_units"
                   placeholder="90000"
                   required>
            <p v-if="errors.stock_units" class="valid-error">{{ errors.stock_units }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit"
                  @click="validateForm"
                  class="btn btn-success">
            {{ $translator.t('CREATE') }}
          </button>
          <!--            <button type="submit" @click="validateForm" class="btn btn-success" data-bs-dismiss="modal">Create</button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translator from "@/utils/translator";
import postStocks from "@/composables/stock/postStocks";
import getStocks from "@/composables/stock/getStocks";

export default {
  props: {
    stock: Object,
    errors: Object,
    valid_success: Object,
  },
  data() {
    return {
      stock_company: null,
      stock_date: null,
      stock_terminated_date: null,
      stock_units: null,
    }
  },
  methods: {
    validateForm() {
      const errors = {};

      if (!this.stock_company) errors.stock_company = translator.t('COMPANY_REQUIRE');
      if (!this.stock_date) errors.stock_date = translator.t('DATE_REQUIRE');
      if (!this.stock_terminated_date) errors.stock_terminated_date = translator.t('TERMINATED_DATE_REQUIRE');
      if (!this.stock_units) errors.stock_units = translator.t('UNITS_REQUIRE');

      this.$emit('update-errors', errors);

      if (Object.keys(errors).length === 0) {
        this.postStocks();
        this.valid_success.message = translator.t('STOCK_ADDED_MESSAGE')
        this.$emit('update-valid-success', this.valid_success);
      }
    },
    postStocks() {
      postStocks({
        company: this.stock_company,
        created: this.stock_date,
        terminatedDate: this.stock_terminated_date,
        numberOfUnits: this.stock_units
      })
          .then(() => {
            getStocks()
                .then(result => this.$emit('update-stocks', result))
                .catch(err => console.log(err.message))
            this.stock_company = null
            this.stock_date = null
            this.stock_terminated_date = null
            this.stock_units = null
          })
          .catch(err => console.log(err.message))
    },
  }
}
</script>

<style scoped>
.valid-error {
  color: red
}

.valid-success {
  color: green;
  font-weight: bold;
}
</style>
