<template>
  <div class="row">
    <h1>{{ $translator.t('FACTOR_USE') }}</h1>
    <div class="col-md-12">
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="usernameInput" class="form-label">{{ $translator.t('USERNAME') }}</label>
          <input type="text" class="form-control" id="usernameInput" v-model="username">
        </div>
        <div class="mb-3">
          <label for="passwordInput" class="form-label">{{ $translator.t('PASSWORD') }}</label>
          <input type="password" class="form-control" id="passwordInput" v-model="password">
        </div>
        <button type="submit" class="btn btn-primary">{{ $translator.t('LOGIN_BUTTON') }}</button>
        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useStore } from 'vuex';
import { API_BASE_URL } from "@/constants/constants";
import translator from "@/utils/translator";

export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');

    const login = async () => {
      try {
        const response = await axios.post(API_BASE_URL + '/api/login_check', {
          username: username.value,
          password: password.value
        });
        localStorage.setItem('token', response.data.token);
        store.dispatch('login', username.value);
        router.push('/');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          errorMessage.value = translator.t('INCORRECT_LOGIN_DATA');
        } else {
          errorMessage.value = translator.t('LOGIN_FAILED');
        }
        console.error('Error login:', error);
      }
    };

    return {
      username,
      password,
      login,
      errorMessage,
    };
  },
};
</script>

<style>
h1 {
  margin-top: 10px;
}
</style>
