<template>
  <div class="modal fade"
       :id="'updateStockModal_' + stock.id"
       tabindex="-1"
       aria-labelledby="updateStockModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="updateStockModalLabel">
            {{ $translator.t('EDIT_STOCK') }}
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="mb-3">
            <label for="editCompanyInput" class="form-label">{{ $translator.t('COMPANY') }}</label>
            <input
                type="text"
                class="form-control"
                id="editCompanyInput"
                v-model="edit_stock.stock_company"
                required>
            <p v-if="errors.stock_company" class="valid-error">{{ errors.stock_company }}</p>
          </div>
          <div class="mb-3">
            <label for="editDateInput" class="form-label">{{ $translator.t('DATE') }}</label>
            <input
                type="date"
                class="form-control"
                id="editDateInput"
                v-model="edit_stock.stock_date"
                required>
            <p v-if="errors.stock_date" class="valid-error">{{ errors.stock_date }}</p>
          </div>
          <div class="mb-3">
            <label for="editTerminatedInput" class="form-label">{{ $translator.t('TERMINATED_DATE') }}</label>
            <input
                type="date"
                class="form-control"
                id="editTerminatedInput"
                v-model="edit_stock.stock_terminated_date"
                required
            >
            <p v-if="errors.stock_terminated_date" class="valid-error">{{ errors.stock_terminated_date }}</p>
          </div>
          <div class="mb-3">
            <label for="editUnitsInput" class="form-label">{{ $translator.t('UNITS') }}</label>
            <input
                type="number"
                class="form-control"
                id="editUnitsInput"
                v-model="edit_stock.stock_units"
                placeholder="90000"
                required
            >
            <p v-if="errors.stock_units" class="valid-error">{{ errors.stock_units }}</p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $translator.t('CANCEL') }}
          </button>
          <button type="button" class="btn btn-primary" @click="patchStocks(stock.id)" data-bs-dismiss="modal">
            {{ $translator.t('EDIT') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import patchStocks from "@/composables/stock/patchStocks";
import getStocks from "@/composables/stock/getStocks";
import translator from "@/utils/translator";

export default {
  props: {
    stock: Object,
    edit_stock: Object,
    errors: Object,
  },
  methods: {
    patchStocks(stockId) {
      const errors = {};

      if (!this.edit_stock.stock_company) errors.stock_company = translator.t('COMPANY_REQUIRE');
      if (!this.edit_stock.stock_date) errors.stock_date = translator.t('DATE_REQUIRE');
      if (!this.edit_stock.stock_terminated_date) errors.stock_terminated_date = translator.t('TERMINATED_DATE_REQUIRE');
      if (!this.edit_stock.stock_units) errors.stock_units = translator.t('UNITS_REQUIRE');

      if (Object.keys(errors).length > 0) {
        this.$emit('update-errors', errors);
        return;
      }

      patchStocks(stockId, {
        company: this.edit_stock.stock_company,
        created: this.edit_stock.stock_date,
        terminatedDate: this.edit_stock.stock_terminated_date,
        numberOfUnits: this.edit_stock.stock_units
      })
          .then(() => {
            getStocks()
                .then(result => this.$emit('update-stocks', result))
                .catch(err => console.log(err.message))
            this.edit_stock.stock_id = null
            this.edit_stock.stock_company = null
            this.edit_stock.stock_date = null
            this.edit_stock.stock_terminated_date = null
            this.edit_stock.stock_units = null
            this.$emit('update-valid-success', {update: translator.t('STOCK_EDITED_MESSAGE')});
          })
          .catch(err => console.log(err.message))
    },
  }
}
</script>
