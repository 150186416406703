<template>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">{{ $translator.t('UNITS') }}</th>
      <th scope="col">{{ $translator.t('NAME') }}</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="stock in stocks" :key="stock.id">
      <td>
        <button type="button"
                class="btn btn-link btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="'#detailsStockModal_' + stock.id">
            <span v-if="!stock.allUnitsUsed">
              [{{ stock.availablePortions }}] - <b>{{ stock.usedUnits }}</b>/{{ stock.numberOfUnits }}
            </span>
          <span v-else>{{ stock.created }} ({{ $translator.t('DELIVERY') }})</span>
        </button>

        <StockDetailsModal
            :stock="stock"
        />

      </td>

      <td>
        <span class="terminated-date" v-if="stock.warningTerminated">{{ stock.company }}</span>
        <span class="will-be-empty" v-else-if="stock.warningWillBeEmpty && !stock.allUnitsUsed">
            {{ stock.company }}
          </span>
        <span v-else>{{ stock.company }}</span>
      </td>

      <td v-if="!stock.allUnitsUsed">

        <div class="dropdown">
          <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
            {{ $translator.t('MORE') }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <button
                  type="button"
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="'#updateStockModal_' + stock.id"
                  @click="initializeEditStock(stock)">
                {{ $translator.t('EDIT') }}
              </button>
            </li>
            <li>
              <button
                  type="button"
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="'#deleteStockModal_' + stock.id">
                {{ $translator.t('DELETE') }}
              </button>
            </li>
          </ul>
        </div>

        <StockEditForm
            :stock="stock"
            :edit_stock="edit_stock"
            :errors="errors"
            @update-stocks="$emit('update-stocks', $event)"
        />

        <StockDeleteModal
            :stock="stock"
            @update-stocks="updateStocks"
            @update-errors="updateErrors"
        />

      </td>
      <td v-else>
        <b>{{ $translator.t('STOCK_USED_INFO') }}</b>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {formatDate} from "@/utils/dateFormatter";
import StockDetailsModal from "@/components/Stock/StockDetailsModal.vue";
import StockDeleteModal from "@/components/Stock/StockDeleteModal.vue";
import StockForm from "@/components/Stock/StockForm.vue";
import StockEditForm from "@/components/Stock/StockEditForm.vue";

export default {
  components: {StockEditForm, StockForm, StockDeleteModal, StockDetailsModal},
  props: {
    stocks: Array,
    errors: Object,
    valid_success: Object,
  },
  data() {
    return {
      edit_stock: {
        stock_id: null,
        stock_company: null,
        stock_date: null,
        stock_terminated_date: null,
        stock_units: null,
      },
      stock_company: null,
      stock_date: null,
      stock_terminated_date: null,
      stock_units: null,
    }
  },
  methods: {
    initializeEditStock(stock) {
      this.edit_stock = {
        stock_id: stock.id,
        stock_company: stock.company,
        stock_date: formatDate(stock.created),
        stock_terminated_date: formatDate(stock.terminatedDate),
        stock_units: stock.numberOfUnits
      };
    },
    updateStocks(updatedStocks) {
      this.$emit('update-stocks', updatedStocks)
    },
    updateErrors(errors) {
      this.$emit('update-errors', errors)
    },
  }
}
</script>

<style scoped>
.terminated-date {
  color: red;
}

.will-be-empty {
  color: orange;
}
</style>
