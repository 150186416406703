<template>
  <div>
    <h1>{{ $translator.t('PANEL') }}</h1>
    <div class="settings">
      <div class="row">
        <div class="col-md-4">

          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="darkModeSwitch"
                    v-model="isDarkMode"
                    @change="toggleDarkMode">
                <label class="form-check-label" for="darkModeSwitch">{{ $translator.t('DARK_MODE') }}</label>
              </div>
            </div>

            <div class="col-md-6">
              <select class="form-select form-select-sm" aria-label="Language select"
                      v-model="language"
                      @change="updateLanguage">
                <option>{{ $translator.t('LANG_PL') }}</option>
                <option>{{ $translator.t('LANG_EN') }}</option>
              </select>
            </div>
          </div>

          <hr>

          <div class="mb-4">
            <div class="input-group">
              <button class="btn btn-secondary" type="button" id="inputEmail"
                      @click="patchUserEmail"
                      :disabled="newEmail === user.email">{{ $translator.t('CHANGE_EMAIL') }}
              </button>
              <input type="text"
                     class="form-control"
                     aria-label="Change email"
                     aria-describedby="inputEmail"
                     v-model="newEmail">
            </div>
            <div v-if="errors.email" id="emailHelpBlock" class="form-text help-block">
              {{ errors.email }}
            </div>
          </div>

          <div class="mb-4">
            <div class="input-group">
              <button class="btn btn-secondary"
                      type="button"
                      id="inputFrequency"
                      @click="patchUserFrequency"
                      :disabled="newFrequency === user.frequency">
                {{ $translator.t('CHANGE_FREQUENCY') }}
              </button>
              <input type="number"
                     class="form-control"
                     aria-label="Change frequency"
                     aria-describedby="inputFrequency"
                     v-model="newFrequency">
            </div>
            <div v-if="errors.frequency" id="frequencyHelpBlock" class="form-text help-block">
              {{ errors.frequency }}
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-2">
              <b>{{ $translator.t('CHANGE_PASSWORD') }}</b>
            </div>

            <PasswordForm
                :errors="errors"
                @new-password="updatePassword"
            />

            <div class="mb-3">
              <button
                  class="btn btn-secondary"
                  @click="patchUserPassword">
                <!--                  :disabled="!isPasswordValid || !hasValidConfirm">-->
                {{ $translator.t('CHANGE_PASSWORD') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="changes-success" v-if="changes_success">{{ changes_success }}</p>
  </div>
</template>

<script>
import {ref} from "vue";
import getUserDetails from "@/composables/user/getUserDetails";
import patchUserPassword from "@/composables/user/patchUserPassword";
import patchUserFrequency from "@/composables/user/patchUserFrequency";
import patchUserEmail from "@/composables/user/patchUserEmail";
import patchUserDarkmode from "@/composables/user/patchUserDarkmode";
import patchUserLanguage from "@/composables/user/patchUserLanguage";
import translator from "@/utils/translator";
import PasswordForm from "@/components/Password/PasswordForm.vue";

export default {
  components: {PasswordForm},
  data() {
    return {
      user: {
        email: null,
        frequency: null,
        language: null,
        dark_mode: null
      },
      isDarkMode: ref(false),
      language: null,
      newEmail: null,
      newFrequency: null,
      newPassword: null,
      confirmationPassword: null,
      isPasswordValid: null,
      hasValidConfirm: null,
      errors: {},
      changes_success: null
    };
  },
  methods: {
    updatePassword(data) {
      this.newPassword = data.newPassword;
      this.confirmationPassword = data.confirmationPassword;
      this.isPasswordValid = data.isPasswordValid;
      this.hasValidConfirm = data.hasValidConfirm;
    },
    patchUserDarkmode() {
      patchUserDarkmode({
        darkmode: this.isDarkMode
      })
          .then(() => console.log('dark mode changed'))
          .catch(err => console.log(err))
    },
    updateLanguage() {
      this.user.language = this.language
      this.patchUserLanguage()
    },
    patchUserLanguage() {
      patchUserLanguage({
        language: this.language.toLowerCase()
      })
          .then(() => {
            localStorage.setItem('user_language', this.language.toLowerCase())
            this.$translator.changeLanguage(this.language.toLowerCase());
          })
          .catch(err => console.log(err))
    },
    patchUserEmail() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (
          this.newEmail !== this.user.email &&
          this.newEmail.match(regexEmail)
      ) {
        patchUserEmail({
          email: this.newEmail
        })
            .then(() => {
              this.changes_success = translator.t('EMAIL_CHANGED_SUCCESS');
              this.errors.email = null
              this.user.email = this.newEmail
            })
            .catch(err => {
              this.errors.email = err
            })
      } else {
        this.errors.email = translator.t('EMAIL_CHANGED_FAILURE');
      }
    },
    patchUserFrequency() {
      if (this.newFrequency !== this.user.frequency) {
        patchUserFrequency({
          frequency: this.newFrequency
        })
            .then(() => {
              this.changes_success = translator.t('FREQUENCY_CHANGED_SUCCESS')
              this.errors.frequency = null
              this.user.frequency = this.newFrequency
            })
            .catch(err => {
              this.errors.frequency = err
            })
      }
    },
    patchUserPassword() {
      if (
          this.newPassword !== null &&
          this.confirmationPassword !== null &&
          this.newPassword === this.confirmationPassword
      ) {
        patchUserPassword({
          newPassword: this.newPassword,
          confirmPassword: this.confirmationPassword,
        })
            .then(() => {
              this.changes_success = translator.t('PASSWORD_CHANGED_SUCCESS')
              this.errors.password = null
              this.newPassword = null
              this.confirmationPassword = null
            })
            .catch(err => {
              this.errors.password = err
            })
      } else {
        this.errors.password = translator.t('PASSWORD_CHANGED_CONFIRM_FAILURE')
      }
    },
    toggleDarkMode() {
      if (this.isDarkMode) {
        document.documentElement.setAttribute("data-bs-theme", "dark");
        localStorage.setItem("dark_mode", "true");
      } else {
        document.documentElement.setAttribute("data-bs-theme", "light");
        localStorage.setItem("dark_mode", "false");
      }

      this.user.dark_mode = this.isDarkMode;

      this.patchUserDarkmode();
    },
  },
  created() {
    getUserDetails()
        .then(result => {
          this.user = result;
          this.newEmail = result.email;
          this.newFrequency = result.frequency;
          this.language = result.language.toUpperCase();

          this.user.dark_mode = result.dark_mode;
          this.isDarkMode = result.dark_mode;

          if (this.isDarkMode) {
            document.documentElement.setAttribute("data-bs-theme", "dark");
            localStorage.setItem("dark_mode", "true");
          } else {
            document.documentElement.setAttribute("data-bs-theme", "light");
            localStorage.setItem("dark_mode", "false");
          }
        })
        .catch(err => console.log(err.message));
  },
  watch: {
    newFrequency(value) {
      if (value <= 0) {
        this.newFrequency = null
      } else if (value !== null && value !== this.user.frequency) {
        this.newFrequency = Number(value).valueOf()
      }
    },
  },
  mounted() {
    const darkModeSetting = localStorage.getItem("dark_mode");
    if (darkModeSetting === "true") {
      this.isDarkMode = ref(true);
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      this.isDarkMode = ref(false);
      document.documentElement.setAttribute("data-bs-theme", "light");
    }
  }
};
</script>

<style scoped>
.help-block {
  color: red;
}

.settings {
  text-align: left;
}

.changes-success {
  color: green;
  font-weight: bold;
}
</style>

