<template>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">{{ $translator.t('DATE') }}</th>
      <th scope="col">{{ $translator.t('UNITS') }}</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="injection in injections" :key="injection.id">
      <td>
        <button type="button"
                class="btn btn-link btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="'#detailsInjectionModal_' + injection.id">
          {{ injection.created }}
        </button>

        <InjectionDetailsModal
            :injection="injection"
        />

      </td>

      <td>
        <span class="faster_injection" v-if="injection.fasterInjection">{{ injection.numberOfUnits }}</span>
        <span v-else>{{ injection.numberOfUnits }}</span>
      </td>

      <td>
        <button type="button"
                class="btn btn-secondary btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="'#deleteInjectionModal_' + injection.id">
          X
        </button>

        <InjectionDeleteModal
            :injection="injection"
            @update-injection="updateInjections"
        />

      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import InjectionDetailsModal from "@/components/Injection/InjectionDetailsModal.vue";
import InjectionDeleteModal from "@/components/Injection/InjectionDeleteModal.vue";

export default {
  components: {
    InjectionDetailsModal,
    InjectionDeleteModal,
  },
  props: {
    injections: Array
  },
  methods: {
    updateInjections(updatedInjections) {
      this.injections = this.$emit('update-injection', updatedInjections)
    },
  }
}
</script>

<style scoped>
.faster_injection {
  color: red;
}
</style>
