<template>
  <div class="modal fade"
       :id="'deleteStockModal_' + stock.id"
       tabindex="-1"
       aria-labelledby="deleteStockModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="deleteStockModalLabel">{{ $translator.t('DELETE_STOCK') }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p style="font-size: large"><b>{{ $translator.t('DELETE_CONFIRM_MESSAGE') }}:</b></p>
          <p><b>{{ stock.company }}</b></p>
          <p><b>{{ stock.created }}</b></p>
          <p><b>{{ stock.stock_terminated_date }}</b></p>
          <p>
            <b>{{ stock.numberOfUnits }}{{ $translator.t('UNITS_SHORT') }} ({{ $translator.t('USED') }}:
              {{ stock.usedUnits }}j.)</b>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $translator.t('CANCEL') }}
          </button>
          <button type="button" class="btn btn-danger" @click="deleteStocks(stock.id)" data-bs-dismiss="modal">
            {{ $translator.t('YES') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deleteStocks from "@/composables/stock/deleteStocks";
import getStocks from "@/composables/stock/getStocks";
import translator from "@/utils/translator";

export default {
  props: {
    stock: Object,
    errors: Object,
  },
  methods: {
    deleteStocks(stockId) {
      deleteStocks(stockId)
          .then(() => {
            getStocks()
                .then(result => this.$emit('update-stocks', result))
                .catch(err => console.log(err.message))
          })
          .catch(err => {
            const errors = {}
            if (err.response && err.response.status === 500) {
              errors.delete_stock = translator.t('DELETE_FAILED_MESSAGE')
            } else if (err.response.status === 406) {
              errors.delete_stock = translator.t('INJECTIONS_EXISTS_IN_STOCK')
            } else {
              console.error("Unexpected error:", err);
            }
            this.$emit('update-errors', errors);
          });
    },
  }
}
</script>
