<template>
  <div>
    <h1>{{ $translator.t('STROKES_HEADER') }}</h1>

    <StrokeDateForm
        @get-strokes="getStrokes"
    />

    <div v-if="count !== null">

      <p v-if="count === 0" class="founded"><b>{{ $translator.t('NOT_FOUNDED_STROKES_MESSAGE') }}</b></p>

      <div v-if="count !== 0">
        <p class="founded">{{ $translator.t('FOUNDED_STROKES_MESSAGE') }}: <b>{{ count }}</b></p>

        <InjectionList
            :injections="injections"
        />

      </div>
    </div>
  </div>
</template>

<script>
import StrokeDateForm from "@/components/Stroke/StrokeDateForm.vue";
import InjectionList from "@/components/Stroke/InjectionList.vue";

export default {
  components: {InjectionList, StrokeDateForm},
  data() {
    return {
      injections: [],
      count: null,
    }
  },
  methods: {
    getStrokes(injections) {
      console.log(injections)
      this.injections = injections
      this.count = this.injections.length
    },
  }
};
</script>

<style>
.founded {
  text-align: left;
}
</style>
